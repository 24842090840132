import { List, Record } from 'immutable';
import { IPUser, User } from './User';

export const DefaultListDataState = {
  loadListDataLoading: false,
  loadListDataError: false,
  loadListDataErrorMessage: '',
  loadDataLoading: false,
  loadDataError: false,
  loadDataErrorMessage: '',
  createDataLoading: false,
  createDataError: false,
  createDataErrorMessage: '',
  listData: List<any>(),
  data: null,
  loadCommentsLoading: false,
  loadCommentsError: false,
  loadCommentsErrorMessage: '',
  comments: List<CommentData>(),
  createCommentLoading: false,
  createCommentError: false,
  createCommentErrorMessage: '',
  deleteCommentLoading: false,
  deleteCommentError: false,
  deleteCommentErrorMessage: '',
  replyCommentId: null,
  commentCurrentPage: 1,
};

export interface CommonStateProps {
  loadListDataLoading: boolean;
  loadListDataError: boolean;
  loadListDataErrorMessage: string;
  loadDataLoading: boolean;
  loadDataError: boolean;
  loadDataErrorMessage: string;
  createDataLoading: boolean;
  createDataError: boolean;
  createDataErrorMessage: string;
  listData: List<any>;
  data: any | null;
  loadCommentsLoading: boolean;
  loadCommentsError: boolean;
  loadCommentsErrorMessage: string;
  comments: List<CommentData>;
  createCommentLoading: boolean;
  createCommentError: boolean;
  createCommentErrorMessage: string;
  deleteCommentLoading: boolean;
  deleteCommentError: boolean;
  deleteCommentErrorMessage: string;
  replyCommentId: number | null;
  commentCurrentPage: number;
}

export interface CommonListPageProps {
  loadListDataLoading: boolean;
  loadListDataError: boolean;
  loadListDataErrorMessage: string;
  listData: any;
}

export interface CommonDataProps {
  loadDataLoading: boolean;
  loadDataError: boolean;
  loadDataErrorMessage: string;
  loadDataStart: (id: number) => void;
  data: any;
}

export interface ChildComment {
  id: number;
  ip_user: IPUser;
  user: User;
  comment: string;
  created: string;
  last_modified: string;
  username: string;
  like_count: number;
  parent: number;
  liked: boolean;
  is_deleted: boolean;
}

export interface CommonComment {
  id: number;
  ip_user: IPUser;
  user: User;
  comment: string;
  created: string;
  last_modified: string;
  username: string;
  like_count: number;
  parent: number;
  liked: boolean;
  is_deleted: boolean;
}

export interface CommentModel extends ChildComment {
  children: List<ChildComment>;
  child_count: number;
}

const ChildCommentRecord = Record({
  id: 0,
  ip_user: null,
  user: null,
  comment: '',
  created: '',
  last_modified: '',
  username: '',
  like_count: 0,
  parent: 0,
  liked: false,
  is_deleted: false,
});

export class ChildCommentData extends ChildCommentRecord
  implements ChildComment {}

const CommentRecord = Record({
  id: 0,
  ip_user: null,
  user: null,
  comment: '',
  created: '',
  last_modified: '',
  username: '',
  like_count: 0,
  child_count: 0,
  parent: 0,
  liked: false,
  is_deleted: false,
  children: List<ChildComment>(),
});

export class CommentData extends CommentRecord {}

export interface CommonCommentParent extends CommonComment {
  children: List<CommonComment>;
  child_count: number;
}

export enum ResourceOwnerStatus {
  IS_WRITTEN_BY_IP_USER = 1,
  IS_WRITTEN_BY_OTHER_USER = 2,
  IS_WRITTEN_BY_CURRENT_USER = 3
}

export enum CommentOwnerStatus {
  IS_WRITTEN_BY_IP_USER = 1,
  IS_WRITTEN_BY_OTHER_USER = 2,
  IS_WRITTEN_BY_CURRENT_USER = 3
}

export function checkCommentOwnerStatus(
  comment: CommonComment,
  currentUser: User,
): CommentOwnerStatus {
  if (comment.ip_user && !currentUser) {
    return CommentOwnerStatus.IS_WRITTEN_BY_IP_USER;
  }
  if (!currentUser || !comment.user || comment.user.id !== currentUser.id) {
    return CommentOwnerStatus.IS_WRITTEN_BY_OTHER_USER;
  }
  return CommentOwnerStatus.IS_WRITTEN_BY_CURRENT_USER;
}

export function checkResourceOwnerStatus(
  resource: any,
  currentUser: User,
): ResourceOwnerStatus {
  if (resource.ip_user && !currentUser) {
    return ResourceOwnerStatus.IS_WRITTEN_BY_IP_USER;
  }
  if (!currentUser || !resource.user || resource.user.id !== currentUser.id) {
    return ResourceOwnerStatus.IS_WRITTEN_BY_OTHER_USER;
  }
  return ResourceOwnerStatus.IS_WRITTEN_BY_CURRENT_USER;
}
