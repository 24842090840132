import { IStoreAction, IStoreState } from 'src/reducers';
import {
  actionCreators,
  CHANGE_COMMENTS_PAGE,
  CREATE_COMMENT_FAILED,
  CREATE_COMMENT_START,
  CREATE_COMMENT_SUCCEEDED,
  DELETE_COMMENT_FAILED,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCEEDED,
  LIKE_COMMENT_FAILED,
  LIKE_COMMENT_START,
  LIKE_FAILED,
  LIKE_START,
  LOAD_COMMENTS_FAILED,
  LOAD_COMMENTS_START,
  LOAD_COMMENTS_SUCCEEDED,
  LOAD_DATA_FAILED,
  LOAD_DATA_START,
  LOAD_DATA_SUCCEEDED,
  CHECK_PASSWORD_FAILED,
  CHECK_PASSWORD_START,
  CHECK_PASSWORD_SUCCEEDED,
  DELETE_DATA_FAILED,
  DELETE_DATA_START,
  DELETE_DATA_SUCCEEDED,
} from 'src/reducers/matchReducer';
import {
  call, fork, put, select, takeLatest,
} from 'redux-saga/effects';
import {
  CreateCommentResult,
  LikeResult,
  LoadCommentsResult,
  LoadPostResult,
} from 'src/models/api/ResponseData';
import Router from 'next/router';
import ToastHelper from '../utils/ToastHelper';

import PostAPI from '../api/MatchAPI';
import {
  CreateCommentParams,
  CreateCommentWithoutUserParams,
  DeleteCommentParams,
  DeleteCommentWithoutUserParams, DeleteDataParams, DeleteDataWithoutUserParams,
} from '../api/CommonAPI';

function* loadDataStartGenerator(action: IStoreAction) {
  const { id } = action.data;

  try {
    const { data }: LoadPostResult = yield call(PostAPI.loadPost, id);
    yield put(actionCreators.loadDataSucceeded({ data }));
  } catch (error) {
    yield put(actionCreators.loadDataFailed(error));
  }
}

function* loadDataStartWatcher() {
  yield takeLatest(LOAD_DATA_START, loadDataStartGenerator);
}

function* loadDataFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(
      ToastHelper.error,
      `데이터 로드 실패... ${action.error.message}`,
    );
  }
}

function* loadDataFailedWatcher() {
  yield takeLatest(LOAD_DATA_FAILED, loadDataFailedGenerator);
}

function* checkPasswordStartGenerator(action: IStoreAction) {
  const { resourceId, password } = action.data;

  try {
    yield call(PostAPI.checkPassword, {
      resourceId,
      password,
    });
    yield put(actionCreators.checkPasswordSucceeded({ resourceId, password }));
  } catch (error) {
    yield put(actionCreators.checkPasswordFailed(error));
  }
}

function* checkPasswordStartWatcher() {
  yield takeLatest(CHECK_PASSWORD_START, checkPasswordStartGenerator);
}

function* checkPasswordSucceededGenerator(action: IStoreAction) {
  yield call(
    Router.push,
    '/matches/[matchId]/update',
    `/posts/${action.data.resourceId}/update`,
  );
  yield call(ToastHelper.success, '비밀번호 확인 성공!!!');
}

function* checkPasswordSucceededWatcher() {
  yield takeLatest(CHECK_PASSWORD_SUCCEEDED, checkPasswordSucceededGenerator);
}

function* checkPasswordFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `비밀번호 확인 실패... ${action.error}`);
  }
}

function* checkPasswordFailedWatcher() {
  yield takeLatest(CHECK_PASSWORD_FAILED, checkPasswordFailedGenerator);
}

function* deleteDataStartGenerator(action: IStoreAction) {
  const { resourceId } = action.data as DeleteDataParams;

  try {
    if ((action.data as DeleteDataWithoutUserParams).password) {
      const { password } = action.data as DeleteDataWithoutUserParams;

      yield call(PostAPI.deleteResource, {
        resourceId,
        password,
      });
    } else {
      yield call(PostAPI.deleteByUserResource, {
        resourceId,
      });
    }

    yield put(actionCreators.deleteDataSucceeded({ resourceId }));
  } catch (error) {
    yield put(actionCreators.deleteDataFailed(error));
  }
}

function* deleteDataStartWatcher() {
  yield takeLatest(DELETE_DATA_START, deleteDataStartGenerator);
}

function* deleteDataSucceededGenerator(action: IStoreAction) {
  yield call(Router.push, '/matches');
  yield call(ToastHelper.info, '해당 게시글을 삭제했습니다');
}

function* deleteDataSucceededWatcher() {
  yield takeLatest(DELETE_DATA_SUCCEEDED, deleteDataSucceededGenerator);
}

function* deleteDataFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `게시글 삭제 실패... ${action.error}`);
  }
}

function* deleteDataFailedWatcher() {
  yield takeLatest(DELETE_DATA_FAILED, deleteDataFailedGenerator);
}

function* loadCommentsStartGenerator(action: IStoreAction) {
  const post = yield select((state: IStoreState) => state.post);
  const currentCommentsPage = post.get('commentCurrentPage');

  const { id } = action.data;

  try {
    const { comments }: LoadCommentsResult = yield call(PostAPI.loadComments, {
      resourceId: id,
      page: currentCommentsPage,
    });
    yield put(actionCreators.loadCommentsSucceeded({ comments }));
  } catch (error) {
    yield put(actionCreators.loadCommentsFailed(error));
  }
}

function* loadCommentsStartWatcher() {
  yield takeLatest(LOAD_COMMENTS_START, loadCommentsStartGenerator);
  yield takeLatest(CHANGE_COMMENTS_PAGE, loadCommentsStartGenerator);
}

function* loadCommentsFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(
      ToastHelper.error,
      `데이터 로드 실패..패. ${action.error.message}`,
    );
  }
}

function* loadCommentsFailedWatcher() {
  yield takeLatest(LOAD_COMMENTS_FAILED, loadCommentsFailedGenerator);
}

function* createCommentFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `댓글 생성 실패... ${action.error.message}`);
  }
}

function* createCommentFailedWatcher() {
  yield takeLatest(CREATE_COMMENT_FAILED, createCommentFailedGenerator);
}

function* deleteCommentStartGenerator(action: IStoreAction) {
  const { commentId } = action.data as DeleteCommentParams;

  try {
    if ((action.data as DeleteCommentWithoutUserParams).commentPassword) {
      const { commentPassword } = action.data as DeleteCommentWithoutUserParams;
      yield call(PostAPI.deleteComment, {
        commentId,
        commentPassword,
      });
    } else {
      yield call(PostAPI.deleteCommentByUser, {
        commentId,
      });
    }

    yield put(actionCreators.deleteCommentSucceeded({ commentId }));
  } catch (error) {
    yield put(actionCreators.deleteCommentFailed(error));
  }
}

function* deleteCommentStartWatcher() {
  yield takeLatest(DELETE_COMMENT_START, deleteCommentStartGenerator);
}

function* deleteCommentSucceededGenerator(action: IStoreAction) {
  yield call(ToastHelper.info, '댓글이 삭제되었습니다');
}

function* deleteCommentSucceededWatcher() {
  yield takeLatest(DELETE_COMMENT_SUCCEEDED, deleteCommentSucceededGenerator);
}

function* deleteCommentFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `댓글 삭제 실패... ${action.error}`);
  }
}

function* deleteCommentFailedWatcher() {
  yield takeLatest(DELETE_COMMENT_FAILED, deleteCommentFailedGenerator);
}

function* likeStartGenerator(action: IStoreAction) {
  const { resourceId } = action.data;

  try {
    const { liked }: LikeResult = yield call(PostAPI.like, resourceId);
    yield put(actionCreators.likeSucceeded({ liked }));
  } catch (error) {
    yield put(actionCreators.likeFailed(error));
  }
}

function* likeStartWatcher() {
  yield takeLatest(LIKE_START, likeStartGenerator);
}

function* likeFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `좋아요 실패... ${action.error.message}`);
  }
}

function* likeFailedWatcher() {
  yield takeLatest(LIKE_FAILED, likeFailedGenerator);
}

function* likeCommentStartGenerator(action: IStoreAction) {
  const { commentId } = action.data;

  try {
    const { liked }: LikeResult = yield call(PostAPI.likeComment, commentId);
    yield put(actionCreators.likeCommentSucceeded({ commentId, liked }));
  } catch (error) {
    yield put(actionCreators.likeCommentFailed(error));
  }
}

function* likeCommentStartWatcher() {
  yield takeLatest(LIKE_COMMENT_START, likeCommentStartGenerator);
}

function* likeCommentFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(
      ToastHelper.error,
      `댓글 좋아요 실패... ${action.error.message}`,
    );
  }
}

function* likeCommentFailedWatcher() {
  yield takeLatest(LIKE_COMMENT_FAILED, likeCommentFailedGenerator);
}

export default function* rootSaga() {
  yield fork(loadDataStartWatcher);
  yield fork(loadDataFailedWatcher);

  yield fork(checkPasswordStartWatcher);
  yield fork(checkPasswordSucceededWatcher);
  yield fork(checkPasswordFailedWatcher);

  yield fork(deleteDataStartWatcher);
  yield fork(deleteDataSucceededWatcher);
  yield fork(deleteDataFailedWatcher);

  yield fork(loadCommentsStartWatcher);
  yield fork(loadCommentsFailedWatcher);

  yield fork(createCommentFailedWatcher);

  yield fork(deleteCommentStartWatcher);
  yield fork(deleteCommentSucceededWatcher);
  yield fork(deleteCommentFailedWatcher);

  yield fork(likeStartWatcher);
  yield fork(likeFailedWatcher);
  yield fork(likeCommentStartWatcher);
  yield fork(likeCommentFailedWatcher);
}
