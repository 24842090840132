import {
  LikeResult,
  LoadCommentsResult,
} from '../models/api/ResponseData';
import {
  requestApi,
  requestApiWithAuthentication,
  requestApiWithAuthIfExist,
} from './request';

export enum RESOURCE {
  SUGGESTION = 'suggestions',
  TOURNAMENT = 'tournaments',
  YEARBOOK_PHOTO = 'yearbook-photos',
  MATCH = 'matches',
  MAIN = 'main',
  USER = 'users'
}

export const RESOURCE_SINGULAR = {
  [RESOURCE.SUGGESTION]: 'suggestion',
  [RESOURCE.TOURNAMENT]: 'tournament',
  [RESOURCE.YEARBOOK_PHOTO]: 'yearbook-photo',
  [RESOURCE.MATCH]: 'post',
  [RESOURCE.MAIN]: 'main',
  [RESOURCE.USER]: 'user',
};

export interface LoadCommentsParams {
  resourceId: number;
  page: number;
}

export interface CreateCommentByUserParams {
  resourceId: number;
  comment: string;
  parent?: number;
}

export interface CreateCommentWithoutUserParams
  extends CreateCommentByUserParams {
  commentUsername: string;
  commentPassword: string;
}

export type CreateCommentParams =
  | CreateCommentWithoutUserParams
  | CreateCommentByUserParams;

export interface DeleteCommentByUserParams {
  commentId: number;
}

export interface DeleteCommentWithoutUserParams
  extends DeleteCommentByUserParams {
  commentPassword: string;
}

export type DeleteCommentParams =
  | DeleteCommentWithoutUserParams
  | DeleteCommentByUserParams;

export interface DeleteDataByUserParams {
  resourceId: number;
}

export interface DeleteDataWithoutUserParams extends DeleteDataByUserParams {
  password: string;
}

export type DeleteDataParams =
  | DeleteDataWithoutUserParams
  | DeleteDataByUserParams;

export interface CheckDataPasswordParams {
  resourceId: number;
  password: string;
}

export abstract class CommonAPI {
  resource = null;

  public loadComments = async ({
    resourceId,
    page,
  }: LoadCommentsParams): Promise<LoadCommentsResult> => {
    const data: LoadCommentsResult = await requestApiWithAuthIfExist(
      `${this.resource}/${resourceId}/comments`,
      {
        method: 'get',
        params: {
          limit: 10,
          offset: 10 * (page - 1),
        },
      },
    );

    return data;
  };

  public deleteComment = async ({
    commentId,
    commentPassword,
  }: DeleteCommentWithoutUserParams): Promise<void> => {
    await requestApi(`${this.resource}/comments/${commentId}`, {
      method: 'DELETE',
      data: {
        password: commentPassword,
      },
    });
  };

  public deleteCommentByUser = async ({
    commentId,
  }: DeleteCommentByUserParams): Promise<void> => {
    await requestApiWithAuthentication(
      `${this.resource}/comments/${commentId}`,
      {
        method: 'DELETE',
      },
    );
  };

  public like = async (resourceId): Promise<LikeResult> => {
    const data: LikeResult = await requestApiWithAuthIfExist(
      `${this.resource}/${resourceId}/likes`,
      {
        method: 'POST',
      },
    );

    return data;
  };

  public likeComment = async (commentId): Promise<LikeResult> => {
    const data: LikeResult = await requestApiWithAuthIfExist(
      `${this.resource}/comments/${commentId}/likes`,
      {
        method: 'POST',
      },
    );

    return data;
  };
}
