import { all } from 'redux-saga/effects';
import layoutSagas from 'src/sagas/layoutSaga';
import loginModalSagas from 'src/sagas/loginModalSaga';
import authOAuthReceiverSagas from 'src/sagas/oAuthReceiverSaga';
import postListPageSagas from 'src/sagas/matchListSaga';
import postDetailPageSagas from 'src/sagas/matchDetailSaga';

export default function* rootSaga() {
  yield all([
    authOAuthReceiverSagas(),
    layoutSagas(),
    loginModalSagas(),
    postListPageSagas(),
    postDetailPageSagas(),
  ]);
}
