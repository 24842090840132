import UserAPI from 'src/api/UserAPI';
import { IStoreAction } from 'src/reducers';
import {
  actionCreators,
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCEEDED,
  REGISTER_FAILED,
  REGISTER_START,
  REGISTER_SUCCEEDED,
  CHECK_EMAIL_START,
  CHECK_USERNAME_START,
} from 'src/reducers/userReducer';
import {
  call, fork, put, takeLatest,
} from 'redux-saga/effects';
import {
  CheckEmailAndUsernameResult,
  LoginResult,
} from '../models/api/ResponseData';
import { setAccessToken } from '../utils/storage';
import { StringUtils } from '../utils/StringUtils';
import ToastHelper from '../utils/ToastHelper';

function* loginStartGenerator(action: IStoreAction) {
  const { email, password } = action.data;

  try {
    const { user, token }: LoginResult = yield call(UserAPI.login, {
      email,
      password,
    });
    yield put(actionCreators.loginSucceeded({ user, token }));
  } catch (error) {
    yield put(actionCreators.loginFailed(error));
  }
}

function* loginStartWatcher() {
  yield takeLatest(LOGIN_START, loginStartGenerator);
}

function* loginSucceededGenerator(action: IStoreAction) {
  setAccessToken(action.data.token, true);
  yield call(ToastHelper.success, '로그인 성공!!!');
}

function* loginSucceededWatcher() {
  yield takeLatest(LOGIN_SUCCEEDED, loginSucceededGenerator);
}

function* loginFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, '로그인 실패... 다시 확인해주세요');
  }
}

function* loginFailedWatcher() {
  yield takeLatest(LOGIN_FAILED, loginFailedGenerator);
}

function* registerStartGenerator(action: IStoreAction) {
  const { email, password, username } = action.data;

  try {
    const { user, token }: LoginResult = yield call(UserAPI.register, {
      email,
      password,
      username,
    });
    yield put(actionCreators.registerSucceeded({ user, token }));
  } catch (error) {
    yield put(actionCreators.registerFailed(error));
  }
}

function* registerStartWatcher() {
  yield takeLatest(REGISTER_START, registerStartGenerator);
}

function* registerSucceededGenerator(action: IStoreAction) {
  // // TODO: 나중에 시간되면 doesRememberThis 를 checkbox 로 입력받아서 변경하자
  setAccessToken(action.data.token, true);
  yield call(actionCreators.hideLoginModal);
  yield call(ToastHelper.success, '회원가입 성공!!!');
}

function* registerSucceededWatcher() {
  yield takeLatest(REGISTER_SUCCEEDED, registerSucceededGenerator);
}

function* registerFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(ToastHelper.error, `회원가입 실패... ${action.error.message}`);
  }
}

function* registerFailedWatcher() {
  yield takeLatest(REGISTER_FAILED, registerFailedGenerator);
}

function* checkEmailStartGenerator(action: IStoreAction) {
  const { email } = action.data;
  if (!StringUtils.isEmail(email)) {
    return;
  }

  try {
    const { is_duplicated }: CheckEmailAndUsernameResult = yield call(
      UserAPI.checkEmail,
      {
        email,
      },
    );
    yield put(
      actionCreators.checkEmailSucceeded({ isDuplicated: is_duplicated }),
    );
  } catch (error) {
    yield put(actionCreators.checkEmailFailed(error));
  }
}

function* checkEmailStartWatcher() {
  yield takeLatest(CHECK_EMAIL_START, checkEmailStartGenerator);
}

function* checkUsernameStartGenerator(action: IStoreAction) {
  const { username } = action.data;
  if (username.length < 2) {
    return;
  }
  try {
    const { is_duplicated }: CheckEmailAndUsernameResult = yield call(
      UserAPI.checkUsername,
      {
        username,
      },
    );
    yield put(
      actionCreators.checkUsernameSucceeded({ isDuplicated: is_duplicated }),
    );
  } catch (error) {
    yield put(actionCreators.checkUsernameFailed(error));
  }
}

function* checkUsernameStartWatcher() {
  yield takeLatest(CHECK_USERNAME_START, checkUsernameStartGenerator);
}

export default function* rootSaga() {
  yield fork(loginStartWatcher);
  yield fork(loginSucceededWatcher);
  yield fork(loginFailedWatcher);

  yield fork(registerStartWatcher);
  yield fork(registerSucceededWatcher);
  yield fork(registerFailedWatcher);

  yield fork(checkEmailStartWatcher);
  yield fork(checkUsernameStartWatcher);
}
