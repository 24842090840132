import { List } from 'immutable';
import { Match, MatchData } from './Match';
import {
  ChildComment,
  ChildCommentData,
  CommentModel,
  CommentData,
} from './Common';

export function childCommentToData(comment: ChildComment) {
  return new ChildCommentData({
    ...comment,
  });
}

export function commentToData(comment: CommentModel) {
  return new CommentData({
    ...comment,
    children: List(comment.children.map(childCommentToData)),
  });
}


export function postToData(post: Match) {
  return new MatchData({
    ...post,
  });
}

export function postsToListData(posts: Match[]) {
  return List(posts.map(postToData));
}
