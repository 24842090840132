import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { matchReducer } from './matchReducer';
import { UserState, UserStateRecord } from '../models/User';
import { PostState, PostStateRecord } from '../models/Match';

export interface IStoreAction {
  type: string;
  data?: any;
  message?: string;
  error?: Error;
}

export interface IStoreState {
  user: UserState;
  post: PostState;
}

export const initialStateRootReducer = {
  user: new UserStateRecord(),
  post: new PostStateRecord(),
};

const rootReducer = combineReducers({
  user: userReducer,
  post: matchReducer,
});

export default rootReducer;
