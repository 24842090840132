import { IStoreAction } from 'src/reducers';
import { PostState } from 'src/models/Match';
import { List } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import {
  childCommentToData,
  commentToData,
  postsToListData,
  postToData,
} from '../models/convertObjectToRecord';
import { LoadPostsParams } from '../api/MatchAPI';
import { ChildComment, CommentModel } from '../models/Common';
import { CheckDataPasswordParams, DeleteDataParams } from '../api/CommonAPI';

// types
const resource = 'post';
export const LOAD_LIST_DATA_START = `${resource}/LOAD_LIST_DATA_START`;
export const LOAD_LIST_DATA_SUCCEEDED = `${resource}/LOAD_LIST_DATA_SUCCEEDED`;
export const LOAD_LIST_DATA_FAILED = `${resource}/LOAD_LIST_DATA_FAILED`;

export const LOAD_DATA_START = `${resource}/LOAD_DATA_START`;
export const LOAD_DATA_SUCCEEDED = `${resource}/LOAD_DATA_SUCCEEDED`;
export const LOAD_DATA_FAILED = `${resource}/LOAD_DATA_FAILED`;

export const CHECK_PASSWORD_START = `${resource}/CHECK_PASSWORD_START`;
export const CHECK_PASSWORD_SUCCEEDED = `${resource}/CHECK_PASSWORD_SUCCEEDED`;
export const CHECK_PASSWORD_FAILED = `${resource}/CHECK_PASSWORD_FAILED`;

export const UPDATE_DATA_START = `${resource}/UPDATE_DATA_START`;
export const UPDATE_DATA_SUCCEEDED = `${resource}/UPDATE_DATA_SUCCEEDED`;
export const UPDATE_DATA_FAILED = `${resource}/UPDATE_DATA_FAILED`;

export const DELETE_DATA_START = `${resource}/DELETE_DATA_START`;
export const DELETE_DATA_SUCCEEDED = `${resource}/DELETE_DATA_SUCCEEDED`;
export const DELETE_DATA_FAILED = `${resource}/DELETE_DATA_FAILED`;

export const UPDATE_CONTENT = `${resource}/UPDATE_POST_CONTENT`;
export const UPDATE_THUMBNAIL = `${resource}/UPDATE_POST_THUMBNAIL`;
export const PUSH_IMAGE_ID = `${resource}/PUSH_IMAGE_ID`;

export const LOAD_COMMENTS_START = `${resource}/LOAD_COMMENTS_START`;
export const LOAD_COMMENTS_SUCCEEDED = `${resource}/LOAD_COMMENTS_SUCCEEDED`;
export const LOAD_COMMENTS_FAILED = `${resource}/LOAD_COMMENTS_FAILED`;
export const CHANGE_COMMENTS_PAGE = `${resource}/CHANGE_COMMENTS_PAGE`;
export const UPDATE_REPLY_COMMENT_ID = `${resource}/UPDATE_REPLY_COMMENT_ID`;

export const CREATE_DATA_START = `${resource}/CREATE_DATA_START`;
export const CREATE_DATA_SUCCEEDED = `${resource}/CREATE_DATA_SUCCEEDED`;
export const CREATE_DATA_FAILED = `${resource}/CREATE_DATA_FAILED`;

export const CREATE_COMMENT_START = `${resource}/CREATE_COMMENT_START`;
export const CREATE_COMMENT_SUCCEEDED = `${resource}/CREATE_COMMENT_SUCCEEDED`;
export const CREATE_COMMENT_FAILED = `${resource}/CREATE_COMMENT_FAILED`;

export const DELETE_COMMENT_START = `${resource}/DELETE_COMMENT_START`;
export const DELETE_COMMENT_SUCCEEDED = `${resource}/DELETE_COMMENT_SUCCEEDED`;
export const DELETE_COMMENT_FAILED = `${resource}/DELETE_COMMENT_FAILED`;

export const LIKE_START = `${resource}/LIKE_START`;
export const LIKE_SUCCEEDED = `${resource}/LIKE_SUCCEEDED`;
export const LIKE_FAILED = `${resource}/LIKE_FAILED`;

export const LIKE_COMMENT_START = `${resource}/LIKE_COMMENT_START`;
export const LIKE_COMMENT_SUCCEEDED = `${resource}/LIKE_COMMENT_SUCCEEDED`;
export const LIKE_COMMENT_FAILED = `${resource}/LIKE_COMMENT_FAILED`;

// actions
function loadListDataStart(data: LoadPostsParams) {
  return {
    type: LOAD_LIST_DATA_START,
    data,
  };
}

function loadListDataSucceeded(data: any) {
  return {
    type: LOAD_LIST_DATA_SUCCEEDED,
    data,
  };
}

function loadListDataFailed(error: Error) {
  return {
    type: LOAD_LIST_DATA_FAILED,
    error,
  };
}

function loadDataStart(id: number) {
  return {
    type: LOAD_DATA_START,
    data: {
      id,
    },
  };
}

function loadDataSucceeded(data: any) {
  return {
    type: LOAD_DATA_SUCCEEDED,
    data,
  };
}

function loadDataFailed(error: Error) {
  return {
    type: LOAD_DATA_FAILED,
    error,
  };
}

function checkPasswordStart(data: CheckDataPasswordParams) {
  return {
    type: CHECK_PASSWORD_START,
    data,
  };
}

function checkPasswordSucceeded(data: any) {
  return {
    type: CHECK_PASSWORD_SUCCEEDED,
    data,
  };
}

function checkPasswordFailed(error: Error) {
  return {
    type: CHECK_PASSWORD_FAILED,
    error,
  };
}

export interface UpdatePostStartParams {
  title: string;
  category: number;
  resourceId: number;
}

function updateDataStart(data: UpdatePostStartParams) {
  return {
    type: UPDATE_DATA_START,
    data,
  };
}

function updateDataSucceeded(data: any) {
  return {
    type: UPDATE_DATA_SUCCEEDED,
    data,
  };
}

function updateDataFailed(error: Error) {
  return {
    type: UPDATE_DATA_FAILED,
    error,
  };
}

function deleteDataStart(data: DeleteDataParams) {
  return {
    type: DELETE_DATA_START,
    data,
  };
}

function deleteDataSucceeded(data: any) {
  return {
    type: DELETE_DATA_SUCCEEDED,
    data,
  };
}

function deleteDataFailed(error: Error) {
  return {
    type: DELETE_DATA_FAILED,
    error,
  };
}

function updateContent(content: string) {
  return {
    type: UPDATE_CONTENT,
    data: {
      content,
    },
  };
}

function updateThumbnail(thumbnail: string) {
  return {
    type: UPDATE_THUMBNAIL,
    data: {
      thumbnail,
    },
  };
}

function pushImageId(imageId: number) {
  return {
    type: PUSH_IMAGE_ID,
    data: {
      imageId,
    },
  };
}

function loadCommentsStart(id: number) {
  return {
    type: LOAD_COMMENTS_START,
    data: {
      id,
    },
  };
}

function loadCommentsSucceeded(data: any) {
  return {
    type: LOAD_COMMENTS_SUCCEEDED,
    data,
  };
}

function loadCommentsFailed(error: Error) {
  return {
    type: LOAD_COMMENTS_FAILED,
    error,
  };
}

function changeCommentsPage(data: any) {
  return {
    type: CHANGE_COMMENTS_PAGE,
    data,
  };
}

function updateReplyCommentId(replyCommentId: number | null) {
  return {
    type: UPDATE_REPLY_COMMENT_ID,
    data: {
      replyCommentId,
    },
  };
}

export interface CreatePostStartParamsByUser {
  title: string;
  category: number;
}

export interface CreatePostStartParamsWithoutUser
  extends CreatePostStartParamsByUser {
  username: string;
  password: string;
}

export type CreatePostStartParams =
  | CreatePostStartParamsWithoutUser
  | CreatePostStartParamsByUser;

function createDataStart(data: CreatePostStartParams) {
  return {
    type: CREATE_DATA_START,
    data,
  };
}

function createDataSucceeded(data: any) {
  return {
    type: CREATE_DATA_SUCCEEDED,
    data,
  };
}

function createDataFailed(error: Error) {
  return {
    type: CREATE_DATA_FAILED,
    error,
  };
}

function createCommentStart(data: any) {
  return {
    type: CREATE_COMMENT_START,
    data,
  };
}

function createCommentSucceeded(data: any) {
  return {
    type: CREATE_COMMENT_SUCCEEDED,
    data,
  };
}

function createCommentFailed(error: Error) {
  return {
    type: CREATE_COMMENT_FAILED,
    error,
  };
}

function deleteCommentStart(data: any) {
  return {
    type: DELETE_COMMENT_START,
    data,
  };
}

function deleteCommentSucceeded(data: any) {
  return {
    type: DELETE_COMMENT_SUCCEEDED,
    data,
  };
}

function deleteCommentFailed(error: Error) {
  return {
    type: DELETE_COMMENT_FAILED,
    error,
  };
}

function likeStart(resourceId: number) {
  return {
    type: LIKE_START,
    data: {
      resourceId,
    },
  };
}

function likeSucceeded(data: any) {
  return {
    type: LIKE_SUCCEEDED,
    data,
  };
}

function likeFailed(error: Error) {
  return {
    type: LIKE_FAILED,
    error,
  };
}

function likeCommentStart(commentId: number) {
  return {
    type: LIKE_COMMENT_START,
    data: {
      commentId,
    },
  };
}

function likeCommentSucceeded(data: any) {
  return {
    type: LIKE_COMMENT_SUCCEEDED,
    data,
  };
}

function likeCommentFailed(error: Error) {
  return {
    type: LIKE_COMMENT_FAILED,
    error,
  };
}

export const actionCreators = {
  loadListDataStart,
  loadListDataSucceeded,
  loadListDataFailed,
  loadDataStart,
  loadDataSucceeded,
  loadDataFailed,
  checkPasswordStart,
  checkPasswordSucceeded,
  checkPasswordFailed,
  updateDataStart,
  updateDataSucceeded,
  updateDataFailed,
  deleteDataStart,
  deleteDataSucceeded,
  deleteDataFailed,
  updateContent,
  updateThumbnail,
  pushImageId,
  loadCommentsStart,
  loadCommentsSucceeded,
  loadCommentsFailed,
  changeCommentsPage,
  updateReplyCommentId,
  createDataStart,
  createDataSucceeded,
  createDataFailed,
  createCommentStart,
  createCommentSucceeded,
  createCommentFailed,
  deleteCommentStart,
  deleteCommentSucceeded,
  deleteCommentFailed,
  likeStart,
  likeSucceeded,
  likeFailed,
  likeCommentStart,
  likeCommentSucceeded,
  likeCommentFailed,
};

// reducers
export function matchReducer(
  currentState = new PostState(),
  action: IStoreAction,
): PostState {
  switch (action.type) {
    case HYDRATE:
      // @ts-ignore
      return action.payload.post.withMutations((state) => {
        state.set('checkedPassword', currentState.checkedPassword);
      });
    case LOAD_LIST_DATA_START:
      return currentState.withMutations((state) => {
        state.set('loadListDataLoading', true).set('loadListDataError', false);
      });
    case LOAD_LIST_DATA_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('loadListDataLoading', false)
          .set('loadListDataError', false)
          .set('listData', postsToListData(action.data.listData))
          .set('totalCount', action.data.totalCount);
      });
    case LOAD_LIST_DATA_FAILED:
      return currentState.withMutations((state) => {
        state.set('loadListDataLoading', false).set('loadListDataError', true);
      });
    case LOAD_DATA_START:
      return currentState.withMutations((state) => {
        state.set('loadDataLoading', true).set('loadDataError', false);
      });
    case LOAD_DATA_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('loadDataLoading', false)
          .set('loadDataError', false)
          .set('data', postToData(action.data.data));
      });
    case LOAD_DATA_FAILED:
      return currentState.withMutations((state) => {
        state.set('loadDataLoading', false).set('loadDataError', true);
      });
    case CHECK_PASSWORD_FAILED:
      return currentState.withMutations((state) => {
        state
          .set('checkPasswordLoading', false)
          .set('checkPasswordError', true);
      });
    case CHECK_PASSWORD_START:
      return currentState.withMutations((state) => {
        state
          .set('checkPasswordLoading', true)
          .set('checkPasswordError', false);
      });
    case CHECK_PASSWORD_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('checkPasswordLoading', false)
          .set('checkPasswordError', false)
          .set('checkedPassword', action.data.password);
      });
    case UPDATE_DATA_FAILED:
      return currentState.withMutations((state) => {
        state.set('updateDataLoading', false).set('updateDataError', true);
      });
    case UPDATE_DATA_START:
      return currentState.withMutations((state) => {
        state.set('updateDataLoading', true).set('updateDataError', false);
      });
    case UPDATE_DATA_SUCCEEDED:
      return currentState.withMutations((state) => {
        state.set('updateDataLoading', false).set('updateDataError', false);
      });
    case DELETE_DATA_START:
      return currentState.withMutations((state) => {
        state.set('deleteDataLoading', true).set('deleteDataError', false);
      });
    case DELETE_DATA_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('deleteDataLoading', false)
          .set('deleteDataError', false)
          .set('data', postToData(action.data.data));
      });
    case DELETE_DATA_FAILED:
      return currentState.withMutations((state) => {
        state.set('deleteDataLoading', false).set('deleteDataError', true);
      });
    case UPDATE_CONTENT:
      return currentState.withMutations((state) => {
        state.set('content', action.data.content);
      });
    case UPDATE_THUMBNAIL:
      if (currentState.get('thumbnail')) {
        return currentState;
      }
      return currentState.withMutations((state) => {
        state.set('thumbnail', action.data.thumbnail);
      });
    case PUSH_IMAGE_ID:
      return currentState.withMutations((state) => {
        const newImageIds = state.get('imageIds');
        newImageIds.push(action.data.imageId);
        state.set('imageIds', newImageIds);
      });
    case LOAD_COMMENTS_START:
      return currentState.withMutations((state) => {
        state.set('loadCommentsLoading', true).set('loadCommentsError', false);
      });
    case LOAD_COMMENTS_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('loadCommentsLoading', false)
          .set('loadCommentsError', false)
          .set('comments', List(action.data.comments.map(commentToData)));
      });
    case LOAD_COMMENTS_FAILED:
      return currentState.withMutations((state) => {
        state.set('loadCommentsLoading', false).set('loadCommentsError', true);
      });
    case CHANGE_COMMENTS_PAGE:
      return currentState.withMutations((state) => {
        state
          .set('loadCommentsLoading', true)
          .set('loadCommentsError', false)
          .set('commentCurrentPage', action.data.page);
      });
    case UPDATE_REPLY_COMMENT_ID:
      return currentState.withMutations((state) => {
        state.set('replyCommentId', action.data.replyCommentId);
      });
    case CREATE_DATA_START:
      return currentState.withMutations((state) => {
        state.set('createDataLoading', true).set('createDataError', false);
      });
    case CREATE_DATA_SUCCEEDED:
      return currentState.withMutations((state) => {
        state.set('createDataLoading', false).set('createDataError', false);
      });
    case CREATE_DATA_FAILED:
      return currentState.withMutations((state) => {
        state.set('createDataLoading', false).set('createDataError', true);
      });
    case CREATE_COMMENT_START:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', true)
          .set('createCommentError', false);
      });
    case CREATE_COMMENT_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', false)
          .set('createCommentError', false)
          .update('comments', (comments) => {
            const parentId = action.data.comment.parent;
            if (parentId) {
              const parentIndex = comments.findIndex(
                (comment) => comment.id === parentId,
              );

              return comments.updateIn(
                [parentIndex, 'children'],
                (childrenComments) => childrenComments.push(
                  childCommentToData(action.data.comment),
                ),
              );
            }
            return comments.push(commentToData(action.data.comment));
          });
      });
    case CREATE_COMMENT_FAILED:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', false)
          .set('createCommentError', true);
      });
    case DELETE_COMMENT_START:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', true)
          .set('createCommentError', false);
      });
    case DELETE_COMMENT_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', false)
          .set('createCommentError', false)
          .update('comments', (comments) => {
            const { commentId } = action.data;
            let parentIndex = comments.findIndex(
              (comment) => comment.id === commentId,
            );

            if (parentIndex !== -1) {
              return comments.withMutations((newComments) => {
                newComments
                  .setIn([parentIndex, 'is_deleted'], true)
                  .setIn([parentIndex, 'comment'], '댓글이 삭제 되었습니다');
              });
            }
            let childIndex;
            comments.forEach((comment, i) => {
              const index = comment.children.findIndex(
                (childComment) => childComment.id === commentId,
              );

              if (index !== -1) {
                parentIndex = i;
                childIndex = index;
              }
            });
            return comments.withMutations((newComments) => {
              newComments
                .setIn(
                  [parentIndex, 'children', childIndex, 'is_deleted'],
                  true,
                )
                .setIn(
                  [parentIndex, 'children', childIndex, 'comment'],
                  '댓글이 삭제 되었습니다',
                );
            });
          });
      });
    case DELETE_COMMENT_FAILED:
      return currentState.withMutations((state) => {
        state
          .set('createCommentLoading', false)
          .set('createCommentError', true);
      });
    case LIKE_SUCCEEDED:
      return currentState.withMutations((state) => {
        const likeCount = action.data.liked
          ? state.getIn(['data', 'like_count']) + 1
          : state.getIn(['data', 'like_count']) - 1;
        state.setIn(['data', 'liked'], action.data.liked);
        state.setIn(['data', 'like_count'], likeCount);
      });
    case LIKE_COMMENT_SUCCEEDED:
      return currentState.withMutations((state) => {
        let parentIndex = null;
        let childIndex = null;

        state.get('comments').forEach((comment: CommentModel, i: number) => {
          if (comment.id === action.data.commentId) {
            parentIndex = i;
          }

          comment.children.forEach((childComment: ChildComment, j: number) => {
            if (childComment.id === action.data.commentId) {
              parentIndex = i;
              childIndex = j;
            }
          });
        });
        if (childIndex !== null) {
          const beforeLiked = state.getIn([
            'comments',
            parentIndex,
            'children',
            childIndex,
            'liked',
          ]);
          const beforeLikeCount = state.getIn([
            'comments',
            parentIndex,
            'children',
            childIndex,
            'like_count',
          ]);
          const afterLikeCount = beforeLikeCount + (beforeLiked ? -1 : 1);
          state
            .setIn(
              ['comments', parentIndex, 'children', childIndex, 'liked'],
              !beforeLiked,
            )
            .setIn(
              ['comments', parentIndex, 'children', childIndex, 'like_count'],
              afterLikeCount,
            );
        } else {
          const beforeLiked = state.getIn(['comments', parentIndex, 'liked']);
          const beforeLikeCount = state.getIn([
            'comments',
            parentIndex,
            'like_count',
          ]);
          const afterLikeCount = beforeLikeCount + (beforeLiked ? -1 : 1);
          state
            .setIn(['comments', parentIndex, 'liked'], !beforeLiked)
            .setIn(['comments', parentIndex, 'like_count'], afterLikeCount);
        }
      });
    default:
      return currentState;
  }
}
