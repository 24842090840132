export interface DropdownOption {
  key: string;
  text: string;
  value: string | number;
}

export class ObjectUtils {
  public static isEmpty(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  }

  public static getDropdownOptions(tags: string[]): DropdownOption[] {
    return tags.map(tag => ({ key: tag, text: tag, value: tag }));
  }
}
