import { IStoreAction } from 'src/reducers';
import {
  actionCreators,
  LOAD_LIST_DATA_FAILED,
  LOAD_LIST_DATA_START,
  LOAD_LIST_DATA_SUCCEEDED,
} from 'src/reducers/matchReducer';
import {
  call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { LoadPostsResult } from 'src/models/api/ResponseData';
import PostAPI from '../api/MatchAPI';
import ToastHelper from '../utils/ToastHelper';

function* loadListDataStartGenerator(action: IStoreAction) {
  try {
    const {
      page, order, query, category,
    } = action.data;

    const { posts, total_count }: LoadPostsResult = yield call(
      PostAPI.loadPosts,
      {
        page,
        order,
        query,
        category,
      },
    );
    yield put(
      actionCreators.loadListDataSucceeded({
        listData: posts,
        totalCount: total_count,
      }),
    );
  } catch (error) {
    yield put(actionCreators.loadListDataFailed(error));
  }
}

function* loadListDataStartWatcher() {
  yield takeLatest(LOAD_LIST_DATA_START, loadListDataStartGenerator);
}

function* loadListDataFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(
      ToastHelper.error,
      `데이터 로드 실패... ${action.error.message}`,
    );
  }
}

function* loadListDataFailedWatcher() {
  yield takeLatest(LOAD_LIST_DATA_FAILED, loadListDataFailedGenerator);
}

export default function* rootSaga() {
  yield fork(loadListDataStartWatcher);
  yield fork(loadListDataFailedWatcher);
}
