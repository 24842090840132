export class StringUtils {
  public static ellipsis(text: string, maxLength: number = 20) {
    return text.length > maxLength
      ? text.substring(0, text.length - 3) + '…'
      : text;
  }

  public static isEmail(text: string) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(text);
  }
}
