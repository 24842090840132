import { IStoreAction } from 'src/reducers';
import { clearStorage } from 'src/utils/storage';
import { Record } from 'immutable';
import { HYDRATE } from 'next-redux-wrapper';
import { UserStateRecord, UserState } from 'src/models/User';
// types
const resource = 'user';
export const LOGIN_START = `${resource}/LOGIN_START`;
export const LOGIN_SUCCEEDED = `${resource}/LOGIN_SUCCEEDED`;
export const LOGIN_FAILED = `${resource}/LOGIN_FAILED`;

export const REGISTER_START = `${resource}/REGISTER_START`;
export const REGISTER_SUCCEEDED = `${resource}/REGISTER_SUCCEEDED`;
export const REGISTER_FAILED = `${resource}/REGISTER_FAILED`;

export const CONVERT_TOKEN_START = `${resource}/CONVERT_TOKEN_START`;
export const CONVERT_TOKEN_SIGN_IN_SUCCEEDED = `${resource}/CONVERT_TOKEN_SIGN_IN_SUCCEEDED`;
export const CONVERT_TOKEN_SIGN_UP_SUCCEEDED = `${resource}/CONVERT_TOKEN_SIGN_UP_SUCCEEDED`;
export const CONVERT_TOKEN_FAILED = `${resource}/CONVERT_TOKEN_FAILED`;

export const CHECK_LOGIN_START = `${resource}/CHECK_LOGIN_START`;
export const CHECK_LOGIN_SUCCEEDED = `${resource}/CHECK_LOGIN_SUCCEEDED`;
export const CHECK_LOGIN_FAILED = `${resource}/CHECK_LOGIN_FAILED`;

export const CHECK_EMAIL_START = `${resource}/CHECK_EMAIL_START`;
export const CHECK_EMAIL_SUCCEEDED = `${resource}/CHECK_EMAIL_SUCCEEDED`;
export const CHECK_EMAIL_FAILED = `${resource}/CHECK_EMAIL_FAILED`;

export const CHECK_USERNAME_START = `${resource}/CHECK_USERNAME_START`;
export const CHECK_USERNAME_SUCCEEDED = `${resource}/CHECK_USERNAME_SUCCEEDED`;
export const CHECK_USERNAME_FAILED = `${resource}/CHECK_USERNAME_FAILED`;

export const SOCIAL_REGISTER_START = `${resource}/SOCIAL_REGISTER_START`;
export const SOCIAL_REGISTER_SUCCEEDED = `${resource}/SOCIAL_REGISTER_SUCCEEDED`;
export const SOCIAL_REGISTER_FAILED = `${resource}/SOCIAL_REGISTER_FAILED`;

export const SHOW_SOCIAL_REGISTRATION_MODAL = `${resource}/SHOW_SOCIAL_REGISTRATION_MODAL`;
export const HIDE_SOCIAL_REGISTRATION_MODAL = `${resource}/HIDE_SOCIAL_REGISTRATION_MODAL`;

export const SHOW_LOGIN_MODAL = `${resource}/SHOW_LOGIN_MODAL`;
export const HIDE_LOGIN_MODAL = `${resource}/HIDE_LOGIN_MODAL`;

export const LOGOUT = `${resource}/LOGOUT`;

// actions
export interface LoginStartParams {
  email: string;
  password: string;
}

function loginStart(data: LoginStartParams) {
  return {
    type: LOGIN_START,
    data,
  };
}

function loginSucceeded(data: any) {
  return {
    type: LOGIN_SUCCEEDED,
    data,
  };
}

function loginFailed(error: Error) {
  return {
    type: LOGIN_FAILED,
    error,
  };
}

export interface ConvertTokenStartParams {
  code: string;
  state: string;
  provider: string;
}

function convertTokenStart(data: ConvertTokenStartParams) {
  return {
    type: CONVERT_TOKEN_START,
    data,
  };
}

function convertTokenSignInSucceeded(data: any) {
  return {
    type: CONVERT_TOKEN_SIGN_IN_SUCCEEDED,
    data,
  };
}

function convertTokenSignUpSucceeded(data: any) {
  return {
    type: CONVERT_TOKEN_SIGN_UP_SUCCEEDED,
    data,
  };
}

function convertTokenFailed(error: Error) {
  return {
    type: CONVERT_TOKEN_FAILED,
    error,
  };
}

export interface RegisterStartParams {
  email: string;
  password: string;
  username: string;
}

function registerStart(data: RegisterStartParams) {
  return {
    type: REGISTER_START,
    data,
  };
}

function registerSucceeded(data: any) {
  return {
    type: REGISTER_SUCCEEDED,
    data,
  };
}

function registerFailed(error: Error) {
  return {
    type: REGISTER_FAILED,
    error,
  };
}

function checkLoginStart() {
  return {
    type: CHECK_LOGIN_START,
  };
}

function checkLoginSucceeded(data: any) {
  return {
    type: CHECK_LOGIN_SUCCEEDED,
    data,
  };
}

function checkLoginFailed(error: Error) {
  return {
    type: CHECK_LOGIN_FAILED,
    error,
  };
}

export interface CheckEmailStartParams {
  email: string;
}

function checkEmailStart(data: CheckEmailStartParams) {
  return {
    type: CHECK_EMAIL_START,
    data,
  };
}

function checkEmailSucceeded(data: any) {
  return {
    type: CHECK_EMAIL_SUCCEEDED,
    data,
  };
}

function checkEmailFailed(error: Error) {
  return {
    type: CHECK_EMAIL_FAILED,
    error,
  };
}

export interface CheckUsernameStartParams {
  username: string;
}

function checkUsernameStart(data: CheckUsernameStartParams) {
  return {
    type: CHECK_USERNAME_START,
    data,
  };
}

function checkUsernameSucceeded(data: any) {
  return {
    type: CHECK_USERNAME_SUCCEEDED,
    data,
  };
}

function checkUsernameFailed(error: Error) {
  return {
    type: CHECK_USERNAME_FAILED,
    error,
  };
}

function showSocialRegistrationModal() {
  return {
    type: SHOW_SOCIAL_REGISTRATION_MODAL,
  };
}

function hideSocialRegistrationModal() {
  return {
    type: HIDE_SOCIAL_REGISTRATION_MODAL,
  };
}

function showLoginModal() {
  return {
    type: SHOW_LOGIN_MODAL,
  };
}

function hideLoginModal() {
  return {
    type: HIDE_LOGIN_MODAL,
  };
}

export interface SocialRegisterStartParams {
  email: string;
  username: string;
}

function socialRegisterStart(data: SocialRegisterStartParams) {
  return {
    type: SOCIAL_REGISTER_START,
    data,
  };
}

function socialRegisterSucceeded(data: any) {
  return {
    type: SOCIAL_REGISTER_SUCCEEDED,
    data,
  };
}

function socialRegisterFailed(error: Error) {
  return {
    type: SOCIAL_REGISTER_FAILED,
    error,
  };
}

function logout() {
  clearStorage();

  return {
    type: LOGOUT,
  };
}

export const actionCreators = {
  loginStart,
  loginSucceeded,
  loginFailed,
  registerStart,
  registerSucceeded,
  registerFailed,
  convertTokenStart,
  convertTokenSignInSucceeded,
  convertTokenSignUpSucceeded,
  convertTokenFailed,
  checkLoginStart,
  checkLoginSucceeded,
  checkLoginFailed,
  checkEmailStart,
  checkEmailSucceeded,
  checkEmailFailed,
  checkUsernameStart,
  checkUsernameSucceeded,
  checkUsernameFailed,
  logout,
  showSocialRegistrationModal,
  hideSocialRegistrationModal,
  showLoginModal,
  hideLoginModal,
  socialRegisterStart,
  socialRegisterSucceeded,
  socialRegisterFailed,
};

// reducers
export function userReducer(
  currentState = new UserStateRecord(),
  action: IStoreAction,
): UserState {
  switch (action.type) {
    case HYDRATE:
      // User 는 값이 유지되어야 한다.
      // @ts-ignore
      return action.payload.user.withMutations((state) => {
        state.set('currentUser', currentState.currentUser);
      });
    case LOGIN_START:
      return currentState.withMutations((state) => {
        state.set('loginLoading', true).set('loginError', false);
      });
    case LOGIN_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('loginLoading', false)
          .set('loginError', false)
          .set('currentUser', action.data.user)
          .set('loginModalVisible', false);
      });
    case LOGIN_FAILED:
      return currentState.withMutations((state) => {
        state.set('loginLoading', false).set('loginError', true);
      });
    case REGISTER_START:
      return currentState.withMutations((state) => {
        state.set('registerLoading', true).set('registerError', false);
      });
    case REGISTER_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('registerLoading', false)
          .set('registerError', false)
          .set('currentUser', action.data.user)
          .set('loginModalVisible', false);
      });
    case REGISTER_FAILED:
      return currentState.withMutations((state) => {
        state.set('registerLoading', false).set('registerError', true);
      });
    case CONVERT_TOKEN_START:
      return currentState.withMutations((state) => {
        state.set('convertTokenLoading', true).set('convertTokenError', false);
      });
    case CONVERT_TOKEN_SIGN_IN_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('convertTokenLoading', false)
          .set('convertTokenError', false)
          .set('token', action.data.token)
          .set('currentUser', action.data.user);
      });
    case CONVERT_TOKEN_SIGN_UP_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('convertTokenLoading', false)
          .set('convertTokenError', false)
          .set('userProfile', action.data.userProfile)
          .set('socialRegistrationModalVisible', true)
          .set('socialRegistrationProvider', action.data.provider);
      });
    case CONVERT_TOKEN_FAILED:
      return currentState.withMutations((state) => {
        state.set('convertTokenLoading', false).set('convertTokenError', true);
      });
    case LOGOUT:
      return currentState.withMutations((state) => {
        state.set('currentUser', null);
      });
    case CHECK_LOGIN_START:
      return currentState.withMutations((state) => {
        state.set('loginLoading', true).set('loginError', false);
      });
    case CHECK_LOGIN_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('loginLoading', false)
          .set('loginError', false)
          .set('currentUser', action.data.user);
      });
    case CHECK_LOGIN_FAILED:
      return currentState.withMutations((state) => {
        state.set('loginLoading', false).set('loginError', true);
      });
    case CHECK_EMAIL_START:
      return currentState.withMutations((state) => {
        state.set('checkEmailLoading', true).set('checkEmailError', false);
      });
    case CHECK_EMAIL_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('checkEmailLoading', false)
          .set('checkEmailError', false)
          .set('isAvailableEmail', !action.data.isDuplicated);
      });
    case CHECK_EMAIL_FAILED:
      return currentState.withMutations((state) => {
        state.set('checkEmailLoading', false).set('checkEmailError', true);
      });
    case CHECK_USERNAME_START:
      return currentState.withMutations((state) => {
        state
          .set('checkUsernameLoading', true)
          .set('checkUsernameError', false);
      });
    case CHECK_USERNAME_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('checkUsernameLoading', false)
          .set('checkUsernameError', false)
          .set('isAvailableUsername', !action.data.isDuplicated);
      });
    case CHECK_USERNAME_FAILED:
      return currentState.withMutations((state) => {
        state
          .set('checkUsernameLoading', false)
          .set('checkUsernameError', true);
      });
    case SHOW_SOCIAL_REGISTRATION_MODAL:
      return currentState.withMutations((state) => {
        state.set('socialRegistrationModalVisible', true);
      });
    case HIDE_SOCIAL_REGISTRATION_MODAL:
      return currentState.withMutations((state) => {
        state.set('socialRegistrationModalVisible', false);
      });
    case SHOW_LOGIN_MODAL:
      return currentState.withMutations((state) => {
        state.set('loginModalVisible', true);
      });
    case HIDE_LOGIN_MODAL:
      return currentState.withMutations((state) => {
        state.set('loginModalVisible', false);
      });
    case SOCIAL_REGISTER_START:
      return currentState.withMutations((state) => {
        state
          .set('socialRegisterLoading', true)
          .set('socialRegisterError', false);
      });
    case SOCIAL_REGISTER_SUCCEEDED:
      return currentState.withMutations((state) => {
        state
          .set('socialRegisterLoading', false)
          .set('socialRegisterError', false)
          .set('currentUser', action.data.user)
          .set('socialRegistrationModalVisible', false);
      });
    case SOCIAL_REGISTER_FAILED:
      return currentState.withMutations((state) => {
        state
          .set('socialRegisterLoading', false)
          .set('socialRegisterError', true);
      });
    default:
      return currentState;
  }
}
