import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { clearStorage, getAccessToken } from 'src/utils/storage';
import { TokenExpiredError, verify } from 'jsonwebtoken';
import * as _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { EnvChecker } from '../utils/EnvChecker';
import {uuid4} from "@sentry/utils";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      clearStorage();
    }
    if (error.response.status !== 404) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

const DEV_API_HOST = 'http://localhost:8080';
const PROD_API_HOST = 'https://api.catsanddogs.cf';

class TokenNotExistError extends Error {}

export async function getAuthorizationHeader() {
  const accessToken = getAccessToken();

  if (!accessToken) {
    throw new TokenNotExistError('로그인 하세여~');
  }
  // TODO : JWT 가 아님.
  // try {
  //   await verify(accessToken, (process.env as any).JWT_SECRET);
  // } catch (err) {
  //   if (err instanceof TokenExpiredError) {
  //     clearStorage();
  //   }
  //   throw err;
  // }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
}

export async function getAnonymousUserHeader() {
  return {
    headers: {
      "X-Anonymous-User-Uid": uuid4(),
    },
  };
}

export function getRequestURL(path: string) {
  const host = EnvChecker.isProduction() ? PROD_API_HOST : DEV_API_HOST;
  return `${host}/api/${path}`;
}

export async function requestApi(
  path: string,
  options?: AxiosRequestConfig,
): Promise<any> {
  return axios({
    url: getRequestURL(path),
    withCredentials: false,
    validateStatus: (status: number) => status >= 200 && status < 300, // default
    ...options,
    maxContentLength: 2000000,
  })
    .then((result) => result.data)
    .catch((error) => {
      throw new Error(JSON.stringify(error));
    });
}

export async function requestApiWithAuthentication(
  path: string,
  options?: AxiosRequestConfig,
): Promise<any> {
  const authorizationHeader = await getAuthorizationHeader();

  return requestApi(path, _.merge(options, authorizationHeader));
}

export async function requestApiWithAuthIfExist(
  path: string,
  options?: AxiosRequestConfig,
): Promise<any> {
  const accessToken = getAccessToken();
  if (accessToken) {
    return requestApiWithAuthentication(path, options);
  }
  return requestApi(path, options);
}


export async function requestApiWithAuthIfExistElseAnonymousUser(
  path: string,
  options?: AxiosRequestConfig,
): Promise<any> {
  const accessToken = getAccessToken();
  if (accessToken) {
    return requestApiWithAuthentication(path, options);
  }
  return requestApiWithAnonymousUser(path, options);
}

export async function requestApiWithAnonymousUser(
  path: string,
  options?: AxiosRequestConfig,
): Promise<any> {
  const anonymousUserHeader = await getAnonymousUserHeader();

  return requestApi(path, _.merge(options, anonymousUserHeader));
}
