import { List, Record } from 'immutable';
import { DropdownOption } from 'src/utils/ObjectUtils';
import { CommentData, CommonStateProps, DefaultListDataState } from './Common';
import { IPUser, User } from './User';

export interface Match {
  id: number;
  title: string;
  icon: string;
  thumbnail_url: string;
  start_at: string;
  end_at: string;
  is_deleted: boolean;
}

const MatchRecord = Record({
  id: 0,
  title: '',
  icon: '',
  thumbnail_url: '',
  start_at: null,
  end_at: '',
  is_deleted: null
});

export class MatchData extends MatchRecord implements Match {}

export interface MatchStateProps extends CommonStateProps {
  listData: List<MatchData>;
  data: MatchData;
  title: string;
  content: string;
  thumbnail: string | null;
  imageIds: number[];
  category: number;
  totalCount: number;
  checkPasswordLoading: boolean;
  checkPasswordError: boolean;
  checkPasswordErrorMessage: string;
  checkedPassword: string;
  updateDataLoading: boolean;
  updateDataError: boolean;
  updateDataErrorMessage: string;
  deleteDataLoading: boolean;
  deleteDataError: boolean;
  deleteDataErrorMessage: string;
}

export const PostStateRecord = Record({
  ...DefaultListDataState,
  data: new MatchData(),
  listData: List<MatchData>(),
  title: '',
  content: '',
  thumbnail: null,
  imageIds: [],
  category: 0,
  totalCount: 10,
  checkPasswordLoading: false,
  checkPasswordError: false,
  checkPasswordErrorMessage: '',
  checkedPassword: '',
  updateDataLoading: false,
  updateDataError: false,
  updateDataErrorMessage: '',
  deleteDataLoading: false,
  deleteDataError: false,
  deleteDataErrorMessage: '',
});

export class PostState extends PostStateRecord implements MatchStateProps {}

export const defaultCategoryOptions: DropdownOption[] = [
  { key: '자유', value: 0, text: '자유' },
  { key: '유머', value: 1, text: '유머' },
];
