import UserAPI from 'src/api/UserAPI';
import { IStoreAction } from 'src/reducers';
import { actionCreators, CHECK_LOGIN_START } from 'src/reducers/userReducer';
import {
  call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { CheckLoginResult } from '../models/api/ResponseData';

function* checkLoginStartGenerator(action: IStoreAction) {
  try {
    const { user }: CheckLoginResult = yield call(UserAPI.checkLogin);

    yield put(actionCreators.checkLoginSucceeded({ user }));
  } catch (error) {
    yield put(actionCreators.checkLoginFailed(error));
  }
}

function* checkLoginStartWatcher() {
  yield takeLatest(CHECK_LOGIN_START, checkLoginStartGenerator);
}

export default function* rootSaga() {
  yield fork(checkLoginStartWatcher);
}
