import {
  CheckDataPasswordParams,
  CommonAPI,
  DeleteDataByUserParams,
  DeleteDataWithoutUserParams,
  RESOURCE,
} from './CommonAPI';
import {CreatePostResult, LoadPostResult, LoadPostsResult,} from '../models/api/ResponseData';
import {requestApi, requestApiWithAuthentication, requestApiWithAuthIfExistElseAnonymousUser} from './request';

export type PostOrderType = 'best' | 'created_at';

export interface LoadPostsParams {
  page: number;
  order: PostOrderType;
  query: string;
  category: number;
}

export interface CreatePostByUserParams {
  title: string;
  content: string;
  thumbnail?: string | null;
  category: number;
  imageIds: number[];
}

export interface CreatePostWithoutUserParams extends CreatePostByUserParams {
  username: string;
  password: string;
}

export type CreatePostParams =
  | CreatePostWithoutUserParams
  | CreatePostByUserParams;

export interface UpdatePostByUserParams {
  resourceId: number;
  title: string;
  thumbnail?: string | null;
  content: string;
  category: number;
}

export interface UpdatePostWithoutUserParams extends UpdatePostByUserParams {
  password: string;
}

export type UpdatePostParams =
  | UpdatePostWithoutUserParams
  | UpdatePostByUserParams;

export class MatchAPI extends CommonAPI {
  resource = RESOURCE.MATCH;

  public loadPosts = async ({
    page,
    order,
    query,
    category,
  }: LoadPostsParams): Promise<LoadPostsResult> => {
    const data: LoadPostsResult = await requestApi(this.resource, {
      method: 'get',
      params: {
        limit: 10,
        offset: 10 * (page - 1),
        order_by: order,
        category,
        query,
      },
    });

    return data;
  };

  public loadPost = async (id: number): Promise<LoadPostResult> => {
    return await requestApiWithAuthIfExistElseAnonymousUser(`${this.resource}/${id}`, {
      method: 'get',
    });
  };

  public createPost = async ({
    title,
    content,
    thumbnail,
    category,
    username,
    password,
    imageIds,
  }: CreatePostWithoutUserParams): Promise<CreatePostResult> => {
    const data: CreatePostResult = await requestApi(this.resource, {
      method: 'POST',
      data: {
        title,
        content,
        thumbnail,
        category,
        username,
        password,
        image_ids: imageIds,
      },
    });

    return data;
  };

  public createPostByUser = async ({
    title,
    content,
    thumbnail,
    category,
    imageIds,
  }: CreatePostByUserParams): Promise<CreatePostResult> => {
    const data: CreatePostResult = await requestApiWithAuthentication(
      this.resource,
      {
        method: 'POST',
        data: {
          title,
          content,
          thumbnail,
          category,
          image_ids: imageIds,
        },
      },
    );

    return data;
  };

  public checkPassword = async ({
    resourceId,
    password,
  }: CheckDataPasswordParams): Promise<void> => {
    await requestApi(`${this.resource}/${resourceId}/password-check`, {
      method: 'post',
      data: {
        password,
      },
    });
  };

  public deleteResource = async ({
    resourceId,
    password,
  }: DeleteDataWithoutUserParams): Promise<void> => {
    await requestApi(`${this.resource}/${resourceId}`, {
      method: 'delete',
      data: {
        password,
      },
    });
  };

  public deleteByUserResource = async ({
    resourceId,
  }: DeleteDataByUserParams): Promise<void> => {
    await requestApiWithAuthentication(`${this.resource}/${resourceId}`, {
      method: 'delete',
    });
  };
}

const API = new MatchAPI();
export default API;
