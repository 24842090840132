import { RESOURCE } from './CommonAPI';
import {
  OAuthConvertTokenResult,
  LoginResult,
  CheckLoginResult,
  CheckEmailAndUsernameResult,
} from '../models/api/ResponseData';
import { requestApi, requestApiWithAuthentication } from './request';
import dataURItoBlob from '../utils/dataURItoBlob';

interface OAuthConvertTokenParams {
  code: string;
  state: string;
  provider: string;
}

interface LoginParams {
  email: string;
  password: string;
}

interface RegisterParams {
  email: string;
  username: string;
  password: string;
}

interface SocialRegisterParams {
  email: string;
  username: string;
  uid: string;
  provider: string;
  profileImage: string;
}

interface CheckEmailParams {
  email: string;
}

interface CheckUsernameParams {
  username: string;
}

export class UserAPI {
  resource = RESOURCE.USER;

  public oAuthConvertToken = async ({
    code,
    state,
    provider,
  }: OAuthConvertTokenParams): Promise<OAuthConvertTokenResult> => {
    const data: OAuthConvertTokenResult = await requestApi(
      `${this.resource}/oauth/callback`,
      {
        params: {
          code,
          state,
          provider,
        },
      },
    );

    return data;
  };

  public login = async ({
    email,
    password,
  }: LoginParams): Promise<LoginResult> => {
    const data: LoginResult = await requestApi(`${this.resource}/login`, {
      method: 'POST',
      data: {
        email,
        password,
      },
    });

    return data;
  };

  public register = async ({
    email,
    username,
    password,
  }: RegisterParams): Promise<LoginResult> => {
    const data: LoginResult = await requestApi(`${this.resource}/register`, {
      method: 'POST',
      data: {
        email,
        password,
        username,
      },
    });

    return data;
  };

  public socialRegister = async ({
    email,
    username,
    uid,
    provider,
    profileImage,
  }: SocialRegisterParams): Promise<LoginResult> => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('username', username);
    formData.append('uid', uid);
    formData.append('provider', provider);
    formData.append(
      'profile_image',
      await dataURItoBlob(profileImage),
      'photo.jpg',
    );

    const data: LoginResult = await requestApi(
      `${this.resource}/oauth/register`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      },
    );

    return data;
  };

  public checkLogin = async (): Promise<CheckLoginResult> => {
    const data: CheckLoginResult = await requestApiWithAuthentication(
      `${this.resource}/check`,
      {
        method: 'GET',
      },
    );

    return data;
  };

  public checkEmail = async ({
    email,
  }: CheckEmailParams): Promise<CheckEmailAndUsernameResult> => {
    const data: CheckEmailAndUsernameResult = await requestApi(
      `${this.resource}/check/email`,
      {
        method: 'GET',
        params: {
          email,
        },
      },
    );

    return data;
  };

  public checkUsername = async ({
    username,
  }: CheckUsernameParams): Promise<CheckEmailAndUsernameResult> => {
    const data: CheckEmailAndUsernameResult = await requestApi(
      `${this.resource}/check/username`,
      {
        method: 'GET',
        params: {
          username,
        },
      },
    );

    return data;
  };
}

const API = new UserAPI();
export default API;
