import createSagaMiddleware, { Task } from 'redux-saga';
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper';
import logger from 'redux-logger';
import * as Sentry from '@sentry/browser';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSentryMiddleware from 'redux-sentry-middleware';
import isClient from './utils/isClient';
import rootSaga from './sagas';
import { UserStateRecord } from './models/User';
import { PostState } from './models/Match';
import rootReducer, { initialStateRootReducer, IStoreState } from './reducers';
import { ObjectUtils } from './utils/ObjectUtils';
import { EnvChecker } from './utils/EnvChecker';

export interface SagaStore extends IStoreState {
  sagaTask?: Task;
}

const makeStore: MakeStore<IStoreState> = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware(); // 리덕스 사가 생성
  const middlewares: any[] = [sagaMiddleware]; // 미들웨어 연결
  if (EnvChecker.isDevelopment() && isClient()) {
    middlewares.push(logger);
  }
  middlewares.push(
    createSentryMiddleware(Sentry, {
      // Optionally pass some options here.
    }),
  );
  const enhancer = EnvChecker.isProduction()
    ? compose(applyMiddleware(...middlewares))
    : composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, initialStateRootReducer, enhancer);
  // @ts-ignore
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

function stateToJS(obj) {
  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    // @ts-ignore
    if (!!value && typeof value.toJSON === 'function') {
      // @ts-ignore
      const newVal = value.toJSON();
      if (typeof newVal === 'object' && !Array.isArray(newVal)) {
        newObj[key] = stateToJS(newVal);
      } else {
        newObj[key] = newVal;
      }
    } else if (value === undefined) {
      newObj[key] = null;
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
}

export const wrapper = createWrapper<IStoreState>(makeStore, {
  // Debug 필요할 때만 켜라. 괜히 계속 나오니까 정신 없다
  // debug: (process.env.NODE_ENV !== 'production'),
  serializeState: (state) => ({
    user: stateToJS(state.user.toJSON()),
    post: stateToJS(state.post.toJSON()),
  }),
  deserializeState: (state) => {
    if (!state || ObjectUtils.isEmpty(state)) {
      return {
        user: new UserStateRecord(),
        post: new PostState(),
      };
    }

    return {
      user: new UserStateRecord(state.user),
      post: new PostState(state.post),
    };
  },
});
