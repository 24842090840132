import { Record } from 'immutable';

export class IPUser {
  public id!: number;

  public ip!: string;
}

export const IPUserRecord = Record({
  id: 0,
  ip: '',
});

export class IPUserData extends IPUserRecord {}

export interface Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
  scope: string;
  expires_in: number;
}

export interface User {
  id: number;
  username: string;
  email: string;
  profile_image: string;
}

export const UserRecord = Record({
  id: 0,
  username: '',
  email: '',
  profile_image: '',
});

export class UserData extends UserRecord {}

export interface UserProfile {
  email: string;
  id: string;
  picture: string;
  name: string;
}

interface UserStateProps {
  loginLoading: boolean;
  loginError: boolean;
  loginErrorMessage: string;
  registerLoading: boolean;
  registerError: boolean;
  registerErrorMessage: string;
  convertTokenLoading: boolean;
  convertTokenError: boolean;
  convertTokenErrorMessage: string;
  currentUser: User | null;
  token: Token | null;
  userProfile: UserProfile;
  socialRegisterLoading: boolean;
  socialRegisterError: boolean;
  socialRegisterErrorMessage: string;
  socialRegistrationModalVisible: boolean;
  loginModalVisible: boolean;
  checkEmailLoading: boolean;
  checkEmailError: boolean;
  checkEmailErrorMessage: string;
  isAvailableEmail: boolean;
  checkUsernameLoading: boolean;
  checkUsernameError: boolean;
  checkUsernameErrorMessage: string;
  isAvailableUsername: boolean;
  socialRegistrationProvider: string;
}

export const UserStateRecord = Record({
  loginLoading: false,
  loginError: false,
  loginErrorMessage: '',
  registerLoading: false,
  registerError: false,
  registerErrorMessage: '',
  convertTokenLoading: false,
  convertTokenError: false,
  convertTokenErrorMessage: '',
  currentUser: null,
  token: null,
  userProfile: null,
  socialRegisterLoading: false,
  socialRegisterError: false,
  socialRegisterErrorMessage: '',
  socialRegistrationModalVisible: false,
  loginModalVisible: false,
  checkEmailLoading: false,
  checkEmailError: false,
  checkEmailErrorMessage: '',
  isAvailableEmail: false,
  checkUsernameLoading: false,
  checkUsernameError: false,
  checkUsernameErrorMessage: '',
  isAvailableUsername: false,
  socialRegistrationProvider: '',
});

export const SOCIAL_PROVIDER_TO_STRING = {
  facebook: '페이스북',
};

export class UserState extends UserStateRecord implements UserStateProps {}
