import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

export default class ToastHelper {
  public static success(text: string) {
    return toast.success(text);
  }

  public static error(text: string) {
    Sentry.captureException(text);
    return toast.error(text);
  }

  public static info(text: string) {
    return toast(text);
  }
}
