import { IStoreAction } from 'src/reducers';
import {
  actionCreators,
  CONVERT_TOKEN_FAILED,
  CONVERT_TOKEN_START,
  CONVERT_TOKEN_SIGN_IN_SUCCEEDED,
} from 'src/reducers/userReducer';
import {
  call, fork, put, takeLatest,
} from 'redux-saga/effects';
import Router from 'next/router';
import UserAPI from '../api/UserAPI';
import {
  OAuthConvertTokenResult,
  OAuthConvertTokenSignInResult,
  OAuthConvertTokenSignUpResult,
} from '../models/api/ResponseData';
import ToastHelper from '../utils/ToastHelper';
import { setAccessToken } from '../utils/storage';

function* convertTokenStartGenerator(action: IStoreAction) {
  const { code, state, provider } = action.data;
  const { pathname, asPath } = JSON.parse(state);
  let redirectPath = '/';
  let redirectAs = '/';

  if (asPath && pathname && !pathname.includes('auth')) {
    redirectPath = pathname;
    redirectAs = asPath;
  }
  try {
    const data: OAuthConvertTokenResult = yield call(
      UserAPI.oAuthConvertToken,
      {
        code,
        state,
        provider,
      },
    );

    yield call(Router.push, redirectPath, redirectAs);

    if ((data as OAuthConvertTokenSignInResult).user !== undefined) {
      const { token, user } = data as OAuthConvertTokenSignInResult;
      yield put(actionCreators.convertTokenSignInSucceeded({ token, user }));
    } else if (
      (data as OAuthConvertTokenSignUpResult).user_profile !== undefined
    ) {
      const {
        user_profile,
        provider: resultProvider,
      } = data as OAuthConvertTokenSignUpResult;
      yield put(
        actionCreators.convertTokenSignUpSucceeded({
          userProfile: user_profile,
          provider: resultProvider,
        }),
      );
    }
  } catch (error) {
    yield call(Router.push, redirectPath, redirectAs);
    yield put(actionCreators.convertTokenFailed(error));
  }
}

function* convertTokenStartWatcher() {
  yield takeLatest(CONVERT_TOKEN_START, convertTokenStartGenerator);
}

function* convertTokenSucceededGenerator(action: IStoreAction) {
  setAccessToken(action.data.token, true);
  yield call(ToastHelper.success, '소셜 로그인 성공!!!');
}

function* convertTokenSucceededWatcher() {
  yield takeLatest(
    CONVERT_TOKEN_SIGN_IN_SUCCEEDED,
    convertTokenSucceededGenerator,
  );
}

function* convertTokenFailedGenerator(action: IStoreAction) {
  if (action.error) {
    yield call(
      ToastHelper.error,
      `소셜 로그인 실패... ${action.error.message}`,
    );
  }
}

function* convertTokenFailedWatcher() {
  yield takeLatest(CONVERT_TOKEN_FAILED, convertTokenFailedGenerator);
}

export default function* rootSaga() {
  yield fork(convertTokenStartWatcher);
  yield fork(convertTokenSucceededWatcher);
  yield fork(convertTokenFailedWatcher);
}
