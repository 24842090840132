import Cookies from 'js-cookie';
import isClient from './isClient';

export const ACCESS_TOKEN_KEY = 'uijolsa_access_token';

export function setAccessTokenInSessionStorage(accessToken: string) {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function isIE(): boolean {
  if (!isClient()) {
    return false;
  }
  const { userAgent } = window.navigator;
  return userAgent.includes('MSIE') || !!navigator.userAgent.match(/Trident.*rv:11\./);
}

export function setAccessTokenInLocalStorage(accessToken: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function setAccessToken(accessToken: string, doesRememberThis: boolean) {
  if (isIE()) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken);
  } else if (doesRememberThis) {
    setAccessTokenInLocalStorage(accessToken);
  } else {
    setAccessTokenInSessionStorage(accessToken);
  }
}

function getAccessTokenInCookie() {
  return Cookies.get(ACCESS_TOKEN_KEY);
}

function getAccessTokenInSessionStorage() {
  if (!isClient() || isIE()) {
    return null;
  }
  return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}

function getAccessTokenInLocalStorage() {
  if (!isClient() || isIE()) {
    return null;
  }

  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getAccessToken(): string | null {
  if (!isClient()) {
    return null;
  }

  return (
    getAccessTokenInCookie()
        || getAccessTokenInSessionStorage()
        || getAccessTokenInLocalStorage()
  );
}

export function clearStorage() {
  if (!isClient()) {
    return;
  }

  if (!isIE() || isIE()) {
    sessionStorage.clear();
    localStorage.clear();
  }
  Cookies.remove(ACCESS_TOKEN_KEY);
}
